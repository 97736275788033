<template>
  <component
    :is="component.component.name"
    v-for="(component, index) in specificComponents"
    :key="`${keyPrefix}-${index}`"
    :component="component"
  ></component>
</template>

<script lang="ts">
import CardSection from '~/components/theme3/components/CardSection.vue';

// common
import Separator from '~/components/library/Separator.vue';
import SingleTeamMember from '~/components/theme3/components/SingleTeamMember.vue';
import BlogsBox from '~/components/theme3/blogs/BlogsBox.vue';
import FormContainer from '~/components/library/FormContainer.vue';
import SingleButtonCta from '~/components/theme3/components/SingleButtonCta.vue';
import PropertiesBox from '~/components/theme3/properties/PropertiesBox.vue';
import KnowledgeHubBox from '~/components/theme3/components/KnowledgeHubBox.vue';

export default defineNuxtComponent({
  props: {
    specificComponents: {
      required: false,
      default: () => [],
    },

    keyPrefix: {
      required: false,
      type: String,
      default: 'dynamicComponent',
    },
  },

  components: {
    CardSection,
    Separator,
    SingleTeamMember,
    BlogsBox,
    FormContainer,
    SingleButtonCta,
    PropertiesBox,
    KnowledgeHubBox,
  },
});
</script>
