<template>
  <div class="tw-flex tw-flex-col tw-gap-y-4">
    <lazy-library-title :html="vars.titleHtml" tag="h3"></lazy-library-title>
    <nuxt-img :src="vars.memberImage" class="tw-object-cover md:tw-max-w-[50vw] lg:tw-max-w-full"> </nuxt-img>
    <div class="tw-flex tw-flex-col tw-gap-y-4">
      <a
        v-for="(info, index) in communicationInfo"
        :key="`communicationInfo-${index}`"
        :href="`${info.type === 'email' ? 'mailto:' : 'tel:'}${info.value}`"
        class="tw-flex tw-items-center tw-gap-x-2"
      >
        <span>
          <svg
            v-if="info.type === 'email'"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M2.66732 13.3334C2.30065 13.3334 1.98665 13.2027 1.72532 12.9414C1.46399 12.6801 1.33354 12.3663 1.33399 12.0001V4.00008C1.33399 3.63342 1.46465 3.31942 1.72599 3.05808C1.98732 2.79675 2.3011 2.6663 2.66732 2.66675H13.334C13.7007 2.66675 14.0147 2.79742 14.276 3.05875C14.5373 3.32008 14.6678 3.63386 14.6673 4.00008V12.0001C14.6673 12.3667 14.5367 12.6807 14.2753 12.9421C14.014 13.2034 13.7002 13.3339 13.334 13.3334H2.66732ZM8.00065 8.66675L13.334 5.33342V4.00008L8.00065 7.33342L2.66732 4.00008V5.33342L8.00065 8.66675Z"
              fill="#222222"
            />
          </svg>
          <svg
            v-else-if="info.type === 'phone'"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.34 10.2533C12.52 10.2533 11.7267 10.12 10.9867 9.88C10.8707 9.8407 10.7461 9.83487 10.627 9.86317C10.5079 9.89147 10.3992 9.95275 10.3133 10.04L9.26667 11.3533C7.38 10.4533 5.61333 8.75333 4.67333 6.8L5.97333 5.69333C6.15333 5.50667 6.20667 5.24667 6.13333 5.01333C5.88667 4.27333 5.76 3.48 5.76 2.66C5.76 2.3 5.46 2 5.1 2H2.79333C2.43333 2 2 2.16 2 2.66C2 8.85333 7.15333 14 13.34 14C13.8133 14 14 13.58 14 13.2133V10.9133C14 10.5533 13.7 10.2533 13.34 10.2533Z"
              fill="#222222"
            />
          </svg>
        </span>
        <span> {{ info.value }} </span>
      </a>
      <div>
        {{ vars.descriptionHtml }}
      </div>
      <div>
        <nuxt-link
          :external="true"
          :to="vars.buttonUrl"
          class="pxp-primary-cta text-uppercase pxp-animate tw-cursor-pointer after:tw-border-inherit"
          :style="`color: var(--${vars.buttonColor}); border-top-color: var(--${vars.buttonColor})`"
          >{{ vars.buttonText }}</nuxt-link
        >
      </div>
    </div>
  </div>
</template>

<script>
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme3SingleTeamMember',

  mixins: [ComponentMixin],

  computed: {
    communicationInfo() {
      return this.groupedVariables.communication_info;
    },
  },
});
</script>
