<template>
  <div class="tw-flex tw-flex-col tw-gap-y-4">
    <lazy-library-title :html="vars.titleHtml" tag="h3"></lazy-library-title>
    <nuxt-link
      :to="vars.buttonUrl"
      :external="true"
      class="tw-flex tw-cursor-pointer tw-items-center tw-justify-center tw-gap-x-3 tw-rounded tw-border-2 tw-border-[var(--camel)] tw-px-7 tw-py-5"
    >
      <span class="tw-font-bold tw-uppercase"> {{ vars.buttonText }} </span>
    </nuxt-link>
  </div>
</template>

<script lang="ts">
import { ComponentMixin } from '~/mixins/component.mixin';

export default defineNuxtComponent({
  name: 'Theme3SingleButtonCta',

  mixins: [ComponentMixin],
});
</script>
